<template>
<div class="div-images" v-for="image in images" v-bind:key="image.filename"> <img :src="'./images/'+image.filename" :alt="image.alt" /></div>
</template>

<script>
import imageUrls from "../images.json";

export default {
  data() {
    return {
      images: imageUrls,
    };
  },
};
</script>

<style scoped>
img {
  width:40%;
  height: auto;
}

@media only screen and (max-width: 600px) {
  img {
    width:100%;
    height: auto;
  }
}
.div-images {
  padding:10px;
}
</style>