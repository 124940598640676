<template>
  <div class="nav-contact-container">
    <div class="nav">
      <h1>SOFIA BROLIN</h1>
      s-brolin@hotmail.com<br>
      0725-514140<br>
      @sbrolin<br>
    </div>
    <div class="top-link" v-if="lastScrollPosition || 0">
      <a href="#top"> top</a>
    </div>
  </div>
    
  <div class="gallery">
    <gallery/>
  </div>
</template>

<script>
// @ is an alias to /src
import gallery from '@/components/gallery.vue'

export default {
  components: {
    gallery
  },
  data () {
    return {
      lastScrollPosition: 0,
      topClass: "show"
    }
  },

  mounted () {
  window.addEventListener('scroll', this.onScroll)
  },

  beforeUnmount () {
  window.removeEventListener('scroll', this.onScroll)
  },

  methods: {
  onScroll () {
    // Get the current scroll position
    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
    // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
    if (currentScrollPosition < 0) {
      return
    }
    // Here we determine whether we need to show or hide the navbar
    //this.showNavbar = currentScrollPosition < this.lastScrollPosition
    // Set the current scroll position as the last scroll position
       this.lastScrollPosition = currentScrollPosition

    if (currentScrollPosition > 0)
    {return this.topclass = "show"}
    else {this.topClass ="hidden"}
  }
},

}

</script>

<style scoped>

.top-link {
  position: fixed;
  bottom: 30px;
  margin-left: 20px;
 
}

.nav-contact-container {
  width: 20%;
  float: left;
  position:fixed;
  margin-left: 20px;
  height: 100vh;
}


.gallery {
  margin-left: 300px; margin-top: 20px;
}

@media only screen and (max-width: 600px) {
  .nav-contact-container {
    position: relative;
    float: none;
    width: 100%;
    margin-left: 5px;
    height: 30%;
  }

  .gallery {
    margin-left: 5px; 
    margin-top: 50px;
  }

  .top-link {
    display: none;
  }
}


.nav h1 {
  font-size: 25px;
}

.nav {
  font-size: 13px;
  margin-top: 40px;
}

</style>
